
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "new-target-modal",
  components: {},
  props: {
    id: String,
    integrationId: String
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const comment = ref();
    const store = useStore();

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      const data = {
        text: comment.value,
        id: props?.id,
        integrationId : props?.integrationId
      };
      store.dispatch(Actions.REPLYCOMMENT, data).then((res) => {
        if (res == true) {
          Swal.fire({
            text: "Yorum cevaplama başarılı",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            comment.value = "";
            hideModal(newTargetModalRef.value);
          });
        } else {
          Swal.fire({
            text: "Yorum cevaplama başarısız oldu",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            comment.value = "";
            hideModal(newTargetModalRef.value);
          });
        }
      });
      console.log(comment.value);
      console.log(props.id);
    };

    return {
      submit,
      loading,
      comment,
      formRef,
      newTargetModalRef,
    };
  },
});
